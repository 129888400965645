<template>
  <hub-modal class="milestone-graph-modal" :closable="true" :visible="true" @close="onClose">
    <template #title>{{ template.title }}</template>
    <component :is="'style'" type="text/css">{{ selectedStyle }}</component>

    <div class="top-bar">
      <div class="select-wrapper">
        <hub-multiselect v-model:value="selected" :options="steps" />
        <hub-button :disabled="!selected" color="primary" @click="onSelect(selected)">Create</hub-button>
      </div>
      <div class="buttons-wrapper">
        <hub-button color="primary" @click="onSelect()">Create generic task</hub-button>
      </div>
    </div>
    <div ref="wrapper" class="wrp"></div>
  </hub-modal>
</template>
<script>
import Modal from '@/components/common/Modal';
import Rete from 'rete';
import ConnectionPlugin from 'rete-connection-plugin';
import VueRenderPlugin from 'rete-vue-render-plugin';
import CustomNodeComponent from './VueComponent.vue';
import ReadonlyPlugin from 'rete-readonly-plugin';
import ConnectionReroutePlugin from './plugin';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';

import payload from './payload';

import { zoomAt } from './utils';

const anyTypeSocket = new Rete.Socket('Any type');

class Component extends Rete.Component {
  constructor(type) {
    super(type);
    this.data.render = 'vue';
    this.data.component = CustomNodeComponent;
  }

  builder(node) {
    node.data.props = node.data.props || this.data.props;
    node.addInput(new Rete.Input(`in____input`, '', anyTypeSocket, true));
    node.addOutput(new Rete.Output(`out____output`, '', anyTypeSocket, true));
  }
}

export default {
  components: {
    'hub-modal': Modal,
    'hub-multiselect': Multiselect,
    'hub-button': Button
  },
  props: {
    template: {
      type: Object,
      default: null
    },
    onSelect: {
      type: Function,
      default: null
    },
    onCancel: {
      type: Function,
      default: null
    },
    templateCollection: {
      type: Array,
      default: () => []
    },
    createdStepIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { selected: null };
  },
  computed: {
    steps() {
      const allSteps = this.templateCollection.map(s => s.type);

      return this.template.steps
        .filter(t => allSteps.includes(t.type) && !this.createdStepIds.includes(t.id))
        .map(t => ({ label: t.title, value: t.id }));
    },
    selectedStyle() {
      return `.wrp .tile.node-${this.selected} { border-color: var(--theme-success); background-color: var(--theme-success); }`;
    }
  },
  async mounted() {
    const container = this.$refs.wrapper;
    const editor = new Rete.NodeEditor('demo@0.1.0', container);
    editor.use(ConnectionPlugin);
    editor.use(VueRenderPlugin);
    editor.use(ConnectionReroutePlugin);

    editor.use(ReadonlyPlugin);
    const component = new Component('generic');

    editor.register(component);
    await editor.fromJSON(
      payload.importData(
        this.template,
        id => {
          this.selected = id;
        },
        this.createdStepIds
      )
    );

    zoomAt(editor);
  },
  methods: {
    onClose() {
      this.onCancel();
    }
  }
};
</script>
<style lang="scss">
.milestone-graph-modal {
  .modal {
    width: 90vw !important;
    height: 90vh !important;
    grid-template-rows: max-content calc(90vh - 50px) max-content;
  }
  .wrp {
    width: 100%;
    height: calc(100% - 60px);
  }
  .top-bar {
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    .select-wrapper {
      grid-gap: 5px;
      display: grid;
      grid-template-columns: 1fr max-content;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
