<template>
  <Pin v-for="pin in pins" :key="pin.x + ' ' + pin.y" :pin="pin" @change="change(pin, $event)" @remove="remove(pin)" />
</template>

<script>
import Pin from './Pin.vue';

export default {
  components: {
    Pin
  },
  inject: ['editor', 'connection'],

  props: { pins: { type: Array, default: null } },
  methods: {
    change(pin, { x, y }) {
      pin.x = x;
      pin.y = y;
      this.editor.view.connections.get(this.connection).update();
    },
    remove(pin) {
      /* eslint-disable */
      this.pins.splice(this.pins.indexOf(pin), 1);
      this.editor.view.connections.get(this.connection).update();
      this.$forceUpdate();
    }
  }
};
</script>
