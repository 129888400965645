import { h, createApp } from 'vue';

import * as d3 from 'd3-shape';
import Pins from './Pins.vue';
import { findRightIndex, alignEndsHorizontally } from './utils';

function install(editor, { curve = d3.curveCatmullRom.alpha(1), curvature = 0.05 }) {
  editor.on('connectionpath', data => {
    const { connection } = data;
    const [x1, y1, x2, y2] = data.points;
    const pins = connection && connection.data.pins ? connection.data.pins : [];
    const points = [[x1, y1], ...pins.map(({ x, y }) => [x, y]), [x2, y2]];
    const transformedPoints = alignEndsHorizontally(points, curvature);

    data.d = d3
      .line()
      .x(d => d[0])
      .y(d => d[1])
      .curve(curve)(transformedPoints);
  });
  editor.on('renderconnection', ({ el, connection }) => {
    const path = el.querySelector('.connection path');
    const pins = connection.data.pins || (connection.data.pins = []);

    if (!path) throw new Error('<path> not found');

    const vueContainer = document.createElement('div');

    el.appendChild(vueContainer);

    createApp({
      provide: {
        editor,
        connection
      },
      render() {
        return h(Pins, { props: { pins } });
      }
    });
  });
}

export default {
  name: 'connection-reroute',
  install
};
