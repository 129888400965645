<template>
  <section class="report-wrapper">
    <header>
      <div class="controls-wrapper">
        <hub-text-field
          v-model="searchText"
          :clear-button="true"
          :debounce="true"
          placeholder="My Active Inventions"
          class="header-element"
          :test-id="'my-inventions-search-input'"
          @change="onSearch"
        />
        <div style="justify-self: center; align-self: center;">sorted by</div>
        <hub-multiselect
          v-model:value="sort"
          :can-deselect="false"
          :can-clear="false"
          :options="[
            { label: 'Next Task Due Date', value: 'next' },
            { label: 'Critical Dates', value: 'critical' }
          ]"
          @change="onSortChange"
        />
      </div>
      <div v-if="isReady">
        <label v-if="collection.length > 0">{{ collection?.length }} of {{ total }} </label>
        <hub-task-modal
          v-if="isReady"
          :selected="selected"
          :task-create-options="taskCreateOptions"
          @close="taskModalClosed"
          @createModalClose="onCreateTaskModalClosed"
          @edited="taskEdited"
        />
        <milestone-modal
          v-if="isMilestoneCreateModalVisible && isReady"
          :invention="milestoneCreateOptions.invention"
          :milestone="milestoneCreateOptions.milestone"
          @close="isMilestoneCreateModalVisible = false"
          @created="onMilestoneCreated"
        />
      </div>
    </header>

    <div v-if="isGetCollectionRequestFailed || isGetInventionsRequestFailed" class="list-item-meta error">
      <label>Something went wrong. Please contact your administrator or try later.</label>
    </div>

    <div v-if="isReady && collection.length === 0 && inventions.length === 0" class="list-item-meta">
      <label>Nothing found for '</label>
      <label style="font-weight: 700">{{ searchText }}</label>
      <label>'. Please try something else.</label>
    </div>

    <div v-if="!isReady" class="report-loading">
      <hub-icon name="loading" spin size="xxlg"></hub-icon>
    </div>
    <div v-else class="report">
      <ul v-if="collection.length" ref="listRootRef" class="list invention-list">
        <template v-for="item of collection" :key="item.id">
          <InventionRow class="list-item invention-list-item" :item="item" :is-expanded="expanded === item.id" @click="toggleOverview(item)" />

          <li v-if="expanded === item.id" class="invention-list-item-foot">
            <div v-if="!details[item.id] || details[item.id].isRequestPending" class="milestone-list-loading">
              <hub-icon name="loading" spin size="lg"></hub-icon>
            </div>
            <div v-else-if="details[item.id].isRequestFailed" class="error">Failed to load milestone list</div>
            <milestones
              v-else
              :milestones="details[item.id].milestones"
              @editTask="onEditTask"
              @taskEdited="onTaskEdited(item)"
              @createTask="$e => onCreateTask(item, $e)"
              @editMilestone="$e => editMilestone(item, $e)"
            />
          </li>
        </template>
        <li v-if="isGetCollectionRequestPending && isGetInventionsRequestPending" class="invention-list-item-loading">
          <hub-icon name="loading" spin size="lg"></hub-icon>
        </li>
        <li v-else-if="total > collection.length">
          <hub-observe :get-root-ref="() => $refs['listRootRef']" @intersect="more">
            <hub-button variant="text" @click="more">more<hub-icon name="refresh" spin></hub-icon></hub-button>
          </hub-observe>
        </li>
      </ul>
      <ul v-else ref="listRootRef" class="list invention-list">
        <template v-for="item of inventions" :key="item.id">
          <InventionRow
            class="list-item invention-list-item"
            :item="item"
            :is-expanded="false"
            :empty="true"
            @onCreateMilestone="onCreateMilestone(item)"
            @onCreateTask="onCreateTask(item)"
          />
        </template>
        <li v-if="isGetCollectionRequestPending && isGetInventionsRequestPending" class="invention-list-item-loading">
          <hub-icon name="loading" spin size="lg"></hub-icon>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from './../common/Icon';
import Button from './../common/Button';
import TextField from './../common/TextField';

import Observer from './../inventions/Observer.vue';
import TaskModal from '../inventions/tasks/TaskModal.vue';
import Milestones from '../inventions/tasks/Milestones.vue';
import MilestoneModal from '../inventions/tasks/CreateMilestone.vue';
import Multiselect from '../common/Multiselect.vue';
import InventionRow from './components/InventionRow.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-observe': Observer,
    'hub-text-field': TextField,
    'hub-task-modal': TaskModal,
    Milestones,
    'milestone-modal': MilestoneModal,
    'hub-multiselect': Multiselect,
    InventionRow
  },
  data() {
    return {
      isReady: false,
      selected: null,
      taskCreateOptions: null,
      expanded: null,
      isCreateModalVisible: false,
      milestoneCreateOptions: null,
      isMilestoneCreateModalVisible: false,
      searchText: '',
      sort: 'next'
    };
  },
  computed: {
    ...mapGetters({
      collection: 'reports/myInventions/collection'
    }),
    ...mapState({
      isGetCollectionRequestPending: s => s.reports.myInventions.isRequestPending,
      isGetCollectionRequestFailed: s => s.reports.myInventions.isRequestFailed,
      total: s => s.reports.myInventions.total,
      details: s => s.reports.myInventions.details,
      inventions: s => s.inventions.collection,
      isGetInventionsRequestPending: s => s.inventions.isGetCollectionRequestPending,
      isGetInventionsRequestFailed: s => s.inventions.isGetCollectionRequestFailed
    })
  },
  created() {
    this.$trackEvent(`'My Inventions' Report opened`);
  },
  async mounted() {
    this.isReady = false;
    this.$trackEvent(`Mounted 'Inventions' report`);
    await this.$store.dispatch('reports/myInventions/getCollection', { qs: encodeURIComponent(this.searchText), sort: this.sort });
    this.isReady = true;
  },
  methods: {
    async onSortChange(sort) {
      this.isReady = false;
      await this.$store.dispatch('reports/myInventions/getCollection', { qs: encodeURIComponent(this.searchText), sort });
      this.isReady = true;
    },
    toggleOverview(item) {
      if (this.expanded === item.id) {
        this.expanded = null;
      } else {
        this.expanded = item.id;
        this.$store.dispatch('reports/myInventions/getDetails', item);
      }
    },
    toggleMilestone(milestone) {
      milestone.expanded = !milestone.expanded;
    },
    async more() {
      if (this.isGetCollectionRequestPending) {
        return;
      }

      this.$trackEvent(`More 'Inventions' report`);
      const scrollTop = this.$refs.listRootRef.scrollTop;
      await this.$store.dispatch('reports/myInventions/getCollection', {
        qs: encodeURIComponent(this.searchText),
        skip: this.collection.length,
        sort: this.sort
      });

      this.$refs.listRootRef.scrollTop = scrollTop;
    },
    onEditTask(task) {
      this.selected = task;
    },
    taskModalClosed() {
      this.selected = null;
      this.taskCreateOptions = null;
    },
    async onCreateTask(invention, milestone = null) {
      this.taskCreateOptions = {
        inventionId: invention.id,
        task: {
          createNew: true,
          assignees: [],
          workflow: {
            ...milestone?.workflow,
            stepId: null
          }
        },
        tasks: milestone ? milestone.tasks : []
      };
    },
    async onCreateTaskModalClosed(event) {
      if (event && event.status == 'created') {
        this.isReady = false;
        const inventionId = this.taskCreateOptions.inventionId;
        this.selected = null;
        this.taskCreateOptions = null;
        this.$trackEvent(`Task created using 'Inventions' report`);
        await this.refresh(inventionId);
        this.isReady = true;
      }
    },
    async onMilestoneCreated() {
      this.$trackEvent(`Milestone created from 'My Active Inventions'`);

      this.isReady = false;
      this.isMilestoneCreateModalVisible = false;

      await new Promise(resolve => setTimeout(resolve, 1000));

      await this.refresh(this.milestoneCreateOptions.invention.id);
      this.milestoneCreateOptions = null;
      this.isReady = true;
    },

    onCreateMilestone(invention) {
      this.milestoneCreateOptions = {
        invention,
        milestone: { createNew: true }
      };

      this.isMilestoneCreateModalVisible = true;
    },

    async refresh(inventionId) {
      try {
        this.isReady = false;
        await Promise.all([
          this.$store.dispatch('reports/myInventions/getCollection', {
            qs: this.searchText,
            sort: this.sort
          }),
          this.$store.dispatch('inventions/getCollection', { text: this.searchText }),
          this.$store.dispatch('reports/myInventions/getDetails', { id: inventionId })
        ]);
      } catch (e) {
      } finally {
        this.isReady = true;
      }
    },
    async onSearch(change) {
      const cleanedChange = change ? change.replaceAll(/[\/,]/gi, '').trim() : '';
      if (this.searchText === cleanedChange) {
        return;
      }

      this.searchText = cleanedChange;
      this.isReady = false;
      try {
        this.$trackEvent(`Search 'Inventions' report`);
        const text = encodeURIComponent(this.searchText);

        await Promise.all([
          this.$store.dispatch('reports/myInventions/getCollection', {
            qs: text,
            sort: this.sort
          }),
          this.$store.dispatch('inventions/getCollection', { text })
        ]);
      } finally {
        this.isReady = true;
      }
    },
    async taskEdited() {
      this.$trackEvent(`Task saved using 'Inventions' report`);
      this.isReady = false;
      const inventionId = this.selected.invention.id;
      this.selected = null;

      await this.refresh(inventionId);

      this.isReady = true;
    },
    editMilestone(invention, milestone) {
      this.milestoneCreateOptions = { invention, milestone };
      this.isMilestoneCreateModalVisible = true;
    },
    async onTaskEdited(item) {
      await this.refresh(item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-meta {
  justify-self: center;
  align-self: center;

  label {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--theme-on-surface-accent);
  }

  &.error {
    label {
      color: var(--theme-error);
    }
  }
}

.report-wrapper {
  max-width: 70%;
  min-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  padding-bottom: 0.5rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .controls-wrapper {
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      grid-gap: 10px;
    }

    h2 {
      background-color: transparent;
      margin: 1rem 0;
    }

    label {
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--theme-on-background-accent);
    }
  }

  .bold {
    font-weight: bold;
  }

  .report-loading {
    justify-self: center;
    align-self: center;
  }

  .report {
    .list {
      margin: 0;
      padding: 0;
      list-style: none;

      &:not(:last-child) {
        margin-right: 6px;
      }

      &:last-child {
        overflow-y: scroll;
        height: 100%;
        box-sizing: border-box;
      }

      &.invention-list {
        display: grid;
        align-content: flex-start;
      }
      .invention-list-item-loading {
        place-self: center;
        padding-top: 0.5rem;
        height: 2.3rem;
        overflow: hidden;
      }

      .milestone-list-loading {
        display: flex;
        justify-content: center;
        height: 5rem;
        align-items: center;
      }
    }
  }

  .invention-list-item-invention {
    grid-template-columns: 30px 230px 1fr 300px;
    height: 55px;

    .invention-list-item-title {
      font-weight: 450;
      color: var(--theme-on-surface-accent);
      font-size: 0.85rem;
    }
    .title {
      font-weight: bold;
    }
  }
}
</style>
