<template>
  <section v-if="isLoading || isGetInventionPending || !invention || isGetMilestonePending" class="loading">
    <hub-icon name="loading" spin size="lg" style></hub-icon>
  </section>
  <template v-else>
    <p-create-task-form
      v-if="showForm"
      :is-request-pending="isCreateRequestPending"
      :is-request-failed="isCreateRequestFailed"
      :invention="invention"
      :template-collection="templateCollection"
      :item="prepopulatedTask || data.task"
      :workflow="data.task.workflow"
      @submit="create"
    />
  </template>
</template>

<script>
import { mapState } from 'vuex';
import { h, createApp } from 'vue';
import Create from './_CreateOrEdit';
import Icon from '@/components/common/Icon';
import MilestoneGraph from './createStages/MilestoneGraph.vue';

/* eslint-disable vue/one-component-per-file */
export default {
  components: {
    'p-create-task-form': Create,
    'hub-icon': Icon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      showForm: this.data.task.workflow && this.data.task.workflow.milestoneId ? false : true,
      prepopulatedTask: null,
      isLoading: false,
      stepTemplate: null
    };
  },
  computed: {
    ...mapState({
      invention: s => s.tasks.invention,
      isGetInventionPending: s => s.tasks.isGetInventionPending,
      templateCollection: s => s.tasks.templateCollection,
      isCreateRequestPending: s => s.tasks.isCreateRequestPending,
      isCreateRequestFailed: s => s.tasks.isCreateRequestFailed,
      isGetMilestonePending: s => s.milestones.isGetRequestPending,
      milestone: s => s.milestones.item
    })
  },
  watch: {
    'data.inventionId': {
      async handler() {
        await this.$store.dispatch('tasks/getInventionById', { inventionId: this.data.inventionId });
      },
      immediate: true
    }
  },
  async created() {
    await this.$store.dispatch('tasks/getTemplateCollection');

    if (this.data.task.workflow && this.data.task.workflow.milestoneId) {
      try {
        await this.$store.dispatch('milestones/getById', {
          milestoneId: this.data.task.workflow.milestoneTemplateId,
          workflowId: this.data.task.workflow.id
        });
        if (this.milestone) {
          this.showModal();
        } else {
          this.showForm = true;
        }
      } catch (e) {
        this.showForm = true;
      }
    }
  },
  methods: {
    async create(data) {
      try {
        const payload = {
          inventionId: this.data.inventionId,
          ...data,
          type: this.stepTemplate ? this.stepTemplate.type : 'generic',
          status: 'to do',
          workflow: data.workflow ?? {
            milestoneId: this.data.task.workflow?.milestoneId,
            id: this.data.task.workflow?.id,
            templateId: this.data.task.workflow?.milestoneTemplateId
          }
        };

        if (this.stepTemplate) {
          payload.options = this.stepTemplate.options;
          payload.workflow.stepId = this.stepTemplate.id;
        }
        await this.$store.dispatch('tasks/create', payload);

        this.$toast.success({
          title: 'Create completed',
          message: `Task was created.`
        });

        this.$emit('close', { status: 'created' });
      } catch (e) {
        this.$toast.error({
          title: 'Create failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async onstepChosen(v) {
      this.stepTemplate = null;
      this.unmountPopup();

      if (!v) {
        this.showForm = true;
        return;
      }
      const paths = this.milestone.paths.filter(path => path.to.step === v);

      if (!paths.length) {
        this.showForm = true;
        return;
      }

      const stepTemplate = this.milestone.steps.find(s => s.id === v);
      const start = this.milestone.eventSources.find(source => source.type === 'milestone-start');
      let path = paths.find(p => p.from.step === start.id && p.to.step === v);

      if (!path) {
        path = paths.find(p => p.to.port === 'to do');
      }

      if (!path) {
        path = paths[0];
      }

      if (!path) {
        this.showForm = true;
        return;
      }

      const template = {
        title: path.to.action.title,
        notes: path.to.action.notes,
        dueAt: path.to.action.dueAt,
        assignees: path.to.action.assignees
      };
      try {
        const values = await this.$store.dispatch('steps/getForm', {
          template,
          model: { thisTask: {} },
          context: {
            inventionId: this.data.inventionId,
            milestoneId: this.data.task.workflow.milestoneId,
            workflowId: this.data.task.workflow.id
          }
        });
        this.prepopulatedTask = { ...values, createNew: true };
        this.showForm = true;
        this.stepTemplate = stepTemplate;
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
    showModal() {
      const self = this;

      this.modalInstance = createApp({
        render() {
          return h(MilestoneGraph, {
            template: self.milestone,
            templateCollection: self.templateCollection,
            createdStepIds: self.data ? self.data.tasks.map(t => t.workflow.stepId) : [],
            onSelect: self.onstepChosen,
            onCancel: self.onCancel
          });
        }
      });

      this.modalInstance.mount(document.getElementById('modal'));
    },
    onSelect(id) {
      this.selected = id;
      this.unmountPopup();
    },
    onCancel() {
      this.unmountPopup();
      this.$emit('close');
    },
    unmountPopup() {
      this.modalInstance.unmount();
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
