<template>
  <ul>
    <li v-for="step of roadmap" :key="step.name">
      <hub-icon :name="getStepStatusIcon(step)" size="sm" :title="getStepTitle(step)" class="icon" />
      <hub-icon v-if="step !== roadmap.slice(-1)[0]" name="minus" size="xs" class="connector" />
    </li>
  </ul>
</template>

<script>
import Icon from './../../common/Icon.vue';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    roadmap: {
      type: Array,
      required: true
    }
  },
  methods: {
    getStepStatusIcon(step) {
      if (this.isStepEmpty(step)) {
        return 'checkbox-blank-circle';
      }

      if (step.total === step.completed) {
        return 'check-circle';
      }

      if (step.completed > 0) {
        return 'circle-half-full';
      }

      return 'circle-outline';
    },
    getStepTitle(step) {
      if (!step) {
        return 'unknown';
      }

      if (this.isStepEmpty(step)) {
        return `${step.name} empty`;
      }

      return `${step.name}: ${step.completed} of ${step.total} completed`;
    },
    isStepEmpty(step) {
      return !step || step.total === 0;
    }
  }
};
</script>

<style scoped>
ul {
  display: table;
  list-style-type: none;
  overflow: hidden;
}

li {
  float: left;
  vertical-align: middle;
}

.icon {
  color: rgb(8, 180, 2);
  vertical-align: middle;
}

.connector {
  vertical-align: middle;
  font-size: 0.8rem;
  color: var(--theme-on-surface-accent);
}
</style>
