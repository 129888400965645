<template>
  <hub-icon v-if="icon" size="md" :name="icon" :class="[style, 'task-status']" :title="status"></hub-icon>
</template>

<script>
import Icon from '../../common/Icon.vue';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    status: {
      type: String,
      default: null,
      validator(value) {
        return value && value.length;
      }
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case 'done':
          return 'checkbox-marked';
        case 'rejected':
          return 'close-circle-outline';
        case 'in progress':
          return 'progress-clock';
        case 'sent to 2AR':
          return 'numeric-2-circle-outline';
        case 'air':
          return 'message';
        case 'cancelled':
          return 'cancel';
        case 'blocked':
          return 'stop-circle-outline';
        case 'approve':
        case 'approve with comments':
          return 'alpha-a-circle-outline';
        case 'on hold':
          return 'pause-circle-outline';
        case 'to do':
          return 'crop-square';
        default:
          return '';
      }
    },
    style() {
      switch (this.status) {
        case 'air':
        case 'cancelled':
        case 'done':
          return 'gray';
        case 'blocked':
        case 'rejected':
          return 'red';
        case 'on hold':
          return 'orange';
        default:
          return 'green';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.task-status {
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
.green {
  color: var(--theme-success);
}
.red {
  color: var(--theme-error);
}
.orange {
  color: var(--theme-warning);
}
.gray {
  color: gray;
}
</style>
