<template>
  <li class="list-item" @click="$emit('click')">
    <!-- column 1 -->
    <div>
      <hub-button v-if="!empty" variant="icon">
        <hub-icon v-if="isExpanded" name="chevron-up" size="sm"></hub-icon>
        <hub-icon v-else name="chevron-down" size="sm"></hub-icon>
      </hub-button>
    </div>
    <!-- column 2 -->
    <div>
      <hub-reference-list :invention="{ id: item.id, references: item.references, title: 'Open Invention' }" />
    </div>
    <!-- column 3 -->
    <div class="roadmap">
      <div class="milestone-title trim" :title="item.title">
        {{ item.title }}
      </div>
      <milestone-roadmap v-if="item.roadmap && item.roadmap.length" :roadmap="item.roadmap" />
    </div>
    <!-- column 4 -->
    <div v-if="!empty" class="tasks" :class="{ 'none-critical-task': !taskIsCriticalTask(item) }">
      <!-- column 4.1 -->
      <div class="invention-list-item-title">
        <div class="task-title trim title-blue" :title="item.nextTaskTitle ?? 'No task'">
          <hub-icon
            v-if="taskIsCriticalTask(item) && warningIcon(item)?.length && item.nextTaskTitle !== 'No active tasks'"
            class="warning-icon"
            :name="warningIcon(item)"
            size="md"
            :title="`This task is critical for '${item.title}' milestone`"
          />
          <div class="trim">{{ item.nextTaskTitle ?? 'No task' }}</div>
        </div>
        <div v-if="!taskIsCriticalTask(item)" class="critical-task-title trim">
          {{ item.nextCriticalTask?.title }}
        </div>
      </div>
      <!-- column 4.2 -->
      <div class="notes" :class="{ 'none-critical-task': !taskIsCriticalTask(item) }">
        <div v-if="item.nextTask?.notes" class="trim">
          <styled-content :flat="true" v-html="item.nextTask?.notes"></styled-content>
        </div>
        <div v-if="!taskIsCriticalTask(item)" class="trim">
          <styled-content :flat="true" v-html="item.nextCriticalTask?.notes"></styled-content>
        </div>
      </div>
      <!-- column 4.3 -->
      <div class="item-dueAt">
        <hub-locale-due-at
          class="task-due-at"
          :due-at="item.dueAt"
          :color-coding-enabled="true"
          :emtpy-due-at-text="item.nextCriticalTask?.dueAt ? 'no due at' : ''"
        />
        <hub-locale-due-at
          v-if="!taskIsCriticalTask(item)"
          class="criticat-task-due-at"
          :due-at="item.nextCriticalTask?.dueAt"
          :color-coding-enabled="true"
        />
      </div>
    </div>
    <div v-else class="add-task-wrapper">
      <hub-button variant="text" class="add-task-button" @click="$emit('onCreateMilestone')">Create Milestone</hub-button>
      <div>|</div>
      <hub-button variant="text" class="add-task-button" @click="$emit('onCreateTask')">Create Task</hub-button>
    </div>
    <div></div>
  </li>
</template>
<script>
import { differenceInHours, endOfDay } from 'date-fns';
import Icon from '../../common/Icon';
import Button from '../../common/Button';

import ReferenceList from '@/components/search/ReferenceList.vue';
import LocaleDueAt from './LocaleDueAt.vue';
import MilestoneRoadmap from './MilestoneRoadmap';
import StyledContent from '@/components/common/editor/StyledContent';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-reference-list': ReferenceList,
    'hub-locale-due-at': LocaleDueAt,

    'milestone-roadmap': MilestoneRoadmap,
    StyledContent
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isExpanded: {
      type: Boolean
    },
    empty: {
      type: Boolean
    }
  },
  emits: ['click', 'onCreateMilestone', 'onCreateTask'],
  methods: {
    taskIsCriticalTask(item) {
      return item.nextTask?.id === item.nextCriticalTask?.id;
    },
    warningIcon(item) {
      const difference = differenceInHours(endOfDay(new Date(item.dueAt)), new Date());
      if (difference <= 24) {
        return 'fire';
      }

      if (difference <= 96 && difference > 24) {
        return 'alert-circle-outline';
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.roadmap {
  margin-right: 0.25rem;
}

.add-task-wrapper {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  height: 30px;

  .add-task-button {
    font-size: 0.7rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    height: 30px;
    &:hover {
      cursor: pointer;
      color: var(--theme-on-primary);
      background: var(--theme-primary-accent);
    }
  }
}

.add-task {
  &:hover {
    cursor: pointer;
    color: var(--theme-on-primary);
    border-radius: 15%;
    background: var(--theme-on-background);
  }
}
.warning-icon {
  color: var(--theme-warning);
  margin-right: 5px;
}
.trim {
  margin-right: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.top {
  align-self: flex-start;
}
.list-item {
  display: grid;

  grid-gap: 0;
  border-bottom: 1px solid var(--theme-highlight);
  background: var(--theme-surface);

  align-content: center;
  align-items: center;

  min-height: 4em;
}

.none-critical-task {
  grid-template-rows: 1fr 1fr;
  align-items: baseline;
  -webkit-line-clamp: 1;
}

.invention-list-item {
  grid-template-columns: 30px 235px 1fr 3fr;
  padding: 5px 0;

  &:hover {
    cursor: pointer;
  }

  .invention-list-item-title {
    color: var(--theme-on-surface-accent);
    display: grid;
    align-items: center;
    grid-template-rows: 1fr;
    grid-gap: 5px;
    padding-right: 10px;
  }

  .tasks {
    grid-template-columns: 1.1fr 0.9fr 65px;
    grid-template-rows: 1fr;
    display: grid;
    margin-right: 0.75rem;
    padding-top: inherit;

    .task-title {
      font-size: 0.85rem;
      font-weight: 800;
      display: flex;
      align-items: center;
    }
    .title-blue {
      color: var(--theme-primary);
    }
    .critical-task-title {
      font-size: 0.8rem;
    }
    .milestone-title {
      font-size: 0.75rem;
      padding: 3px 0;
    }
    .notes {
      font-style: italic;
      font-size: 0.8rem;
      -webkit-line-clamp: 3;
      display: grid;
      grid-gap: 5px;
      grid-template-rows: 1fr;
      align-items: center;
    }

    .item-dueAt {
      color: var(--theme-on-surface-accent);
      font-size: 0.75rem;
      padding-right: 10px;
      display: grid;
      align-items: center;
      grid-template-rows: 1fr;

      .task-due-at {
        font-weight: 700;
      }
      .criticat-task-due-at {
        font-weight: 500;
        padding: 5px 0;
      }
    }
  }
}
</style>
