<template>
  <div class="sticky-note">
    <div class="content" :class="{ active: isEditing, createNew }" @dblclick="startEditing">
      <div class="buttons-holder">
        <template v-if="isEditing">
          <hub-button variant="icon" @click="save">
            <hub-icon name="check" size="sm" />
          </hub-button>
          <hub-button variant="icon" @click="onCancel">
            <hub-icon name="close" size="sm" />
          </hub-button>
        </template>
        <template v-else>
          <hub-button variant="icon" @click="startEditing">
            <hub-icon name="pencil" size="sm" />
          </hub-button>
          <hub-button variant="icon" @click="$emit('deleted')">
            <hub-icon name="delete" size="sm" />
          </hub-button>
        </template>
      </div>
      <styled-content v-if="!isEditing" :light="true">
        <div v-html="data[field]"></div>
      </styled-content>
      <div v-else class="field-wrapper">
        <hub-editor ref="input" v-model="initialValue" size="sm" :light="true" />
      </div>
      <div class="description">
        <div v-if="data.updatedBy">Updated by {{ data.updatedBy.split('@')[0] }} {{ new Date(data.updatedAt).toLocaleDateString() }}</div>
        <div v-else-if="data.createdBy">Created by {{ data.createdBy.split('@')[0] }} {{ new Date(data.createdAt).toLocaleDateString() }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from './Button.vue';
import Icon from './Icon.vue';
import Editor from '@/components/common/editor/Editor';
import StyledContent from '@/components/common/editor/StyledContent';

export default {
  components: {
    // TextField,
    'hub-button': Button,
    'hub-icon': Icon,
    'hub-editor': Editor,
    StyledContent
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean
    },
    createNew: {
      type: Boolean,
      default: false
    }
  },
  emits: ['edited', 'deleted'],
  data() {
    return {
      isEditing: this.createNew,
      initialValue: null
    };
  },
  methods: {
    startEditing() {
      if (!this.editable || this.isEditing) {
        return false;
      }
      this.isEditing = true;
      this.initialValue = this.data[this.field];
    },
    save() {
      this.$emit('edited', this.initialValue);
      this.isEditing = false;
    },
    onCancel() {
      this.isEditing = false;
      if (this.createNew) {
        this.$emit('deleted');
      }
    }
  }
};
</script>
<style lang="scss">
.sticky-note {
  .content {
    .buttons-holder {
      button {
        &:hover {
          color: #000;
        }
      }
    }
    .field-wrapper {
      textarea {
        font-size: 0.6rem;
        background: transparent;
        color: #000;
        border: none;
        padding: 0;
        &:focus,
        &:active {
          color: #000;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.sticky-note {
  text-decoration: none;
  color: #000;
  font-size: 0.6rem;
  display: block;
  height: 30px;
  margin: 10px;
  position: relative;

  .content {
    position: relative;
    height: 150px;
    overflow-y: hidden;
    padding-top: 2em;
    padding-bottom: 0;
    transition: transform 0.15s linear;
    background: #ffc;
    box-shadow: 0px 1px 4px rgb(33 33 33 / 70%);
    position: relative;
    transform: rotate(-7deg);
    display: grid;
    grid-template-rows: 1fr max-content;
    border-bottom-left-radius: 30px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 15px;
      height: 20px;
      background: #ffa;
      box-shadow: 3px -2px 10px rgb(0 0 0 / 20%), inset 15px -15px 15px rgb(0 0 0 / 30%);
      left: 0;
      bottom: 0;
      z-index: 2;
      transform: skewX(25deg);
    }
    .buttons-holder {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:hover,
    &.active {
      .buttons-holder {
        display: block;
      }
      z-index: 10;
      transform: rotate(0);
      height: auto;
      min-height: 150px;
    }
    &.active {
      z-index: 9;
    }
    &.createNew {
      z-index: 11;
    }
    .description {
      padding: 5px;
      font-size: 0.6rem;
      display: flex;
      justify-content: flex-end;
      font-style: italic;
      color: grey;
      div {
        &:last-child {
          text-align: right;
        }
        &:first-child {
          text-align: left;
        }
      }
    }
  }
}
</style>
