<template>
  <div v-if="milestoneId" class="notes-wrapper" :class="{ contains: milestoneNotes[milestoneId]?.collection.length }">
    <hub-button :disabled="isAddingNote" variant="icon" @click="isAddingNote = true"><hub-icon name="sticker-plus-outline"/></hub-button>
    <sticky-note
      v-for="note in milestoneNotes[milestoneId]?.collection"
      :key="note.id"
      :editable="true"
      :data="note"
      field="note"
      @edited="$e => onNotesEdited($e, note)"
      @deleted="onNotesDeleted(note)"
    />
    <sticky-note
      v-if="isAddingNote"
      :editable="true"
      :data="{}"
      :create-new="true"
      field="note"
      @edited="onNoteAdded"
      @deleted="isAddingNote = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import StickyNote from '@/components/common/StickyNote';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  components: {
    StickyNote,
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    milestoneId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isAddingNote: false
    };
  },
  computed: {
    ...mapState({
      milestoneNotes: s => s.milestoneNotes.notes
    })
  },
  async created() {
    if (!this.milestoneNotes[this.milestoneId] && this.milestoneId) {
      await this.$store.dispatch('milestoneNotes/getNotesForMilestones', [this.milestoneId]);
    }
  },
  methods: {
    async onNotesEdited(v, note) {
      await this.$store.dispatch('milestoneNotes/updateNote', { id: note.id, payload: { note: v } });
    },
    async onNoteAdded(note) {
      try {
        await this.$store.dispatch('milestoneNotes/createNote', { note, milestoneId: this.milestoneId });
      } finally {
        this.isAddingNote = false;
      }
    },
    async onNotesDeleted(note) {
      await this.$store.dispatch('milestoneNotes/deleteNote', note);
    }
  }
};
</script>
<style lang="scss" scoped>
.notes-wrapper {
  padding-left: 10px;
  &.contains {
    padding-bottom: 150px;
  }
}
</style>
